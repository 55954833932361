import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import HeroImage from "../components/HeroImage/heroImage"
import image from "../assets/images/wagon.jpg"
import imageF from "../../static/img/grid-f.jpg"
import imageA from "../../static/img/grid-a.jpg"
import imageB from "../../static/img/grid-b.jpg"
import imageC from "../../static/img/grid-c.jpg"
import imageD from "../../static/img/grid-d.jpg"
import imageE from "../../static/img/grid-e.jpg"

import "../components/Sidebar/sidebar.scss"
import "../assets/sass/theme/pages/about.scss"
import CtaBanner from "../components/CtaBanner/CtaBanner"

const AboutPage = () => (
  <Layout>
    <SEO
      title="About CNR Engineering"
      url="about"
      description="CNR Engineering is a family run business specialising in engineering, fabrication, precision CNC engineering, machinery installation and removals."
    />
    <HeroImage title="CNR Engineering" subtitle="About" image={image} position="center bottom" padding="150px 0" />

    <div className="page-width">
      <main className="about-page__container">
        <section className="main-with-side">
          <div style={{maxWidth: "750px"}}>
            <p className="lead-paragraph">CNR Engineering is a family run business specialising in engineering, fabrication, precision CNC engineering, machinery installation and removals.</p>
            <p>We have a well-equipped fleet of wagons and lifting equipment which alongside our long-established fabrication and engineering workshop enables our team of highly skilled fabricators, engineers and machine operatives to carry out a wide range of work across the UK.</p>
            <p>We can move individual machines or can offer a full turnkey package to suit your requirements.</p>
          </div>
        </section>
        <aside className="about-grid__aside">
          <div className="about-grid">
            <div className="f about-grid__item" style={{ backgroundImage: `url('${imageF}')`, backgroundPosition: `center center` }}></div>
            <div className="a about-grid__item" style={{ backgroundImage: `url('${imageA}')`, backgroundPosition: `center center` }}></div>
            <div className="b about-grid__item" style={{ backgroundImage: `url('${imageB}')`, backgroundPosition: `center center` }}></div>
            <div className="c about-grid__item" style={{ backgroundImage: `url('${imageC}')`, backgroundPosition: `center center` }}></div>
            <div className="d about-grid__item" style={{ backgroundImage: `url('${imageD}')`, backgroundPosition: `center center` }}></div>
            <div className="e about-grid__item" style={{ backgroundImage: `url('${imageE}')`, backgroundPosition: `center center` }}></div>
          </div>
        </aside>
      </main>
    </div>
    <CtaBanner />
  </Layout>
)

export default AboutPage
